import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Select,
  Button,
  Input,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Kite Size Calculator",
    link: "/kite-size-calculator/",
  },
]
const seeAlsoArray = [
  "/date-and-time-calculator",
  "/fuel-consumption-calculator",
  "/mortgage-loan-calculator",
  "/percentage-calculator",
  "/rep-max-calculator",
  "/shopping-calculator",
]

function KiteSizeCalculator(props) {
  let weight = useRef("")
  let weightMeasure = useRef("")
  let wind = useRef("")
  let windMeasure = useRef("")
  let exp = useRef("")
  let [Result, setResult] = useState("")

  function calcVol(e) {
    const f = 2.2
    let weightElement = parseInt(weight.current.value, 10)
    let windElement = parseInt(wind.current.value, 10)
    let expElement = parseInt(exp.current.value, 10)
    let weightMeasureElement = weightMeasure.current.value
    let windMeasureElement = windMeasure.current.value

    //   let resultForm = document.getElementById("result");

    var Conditions = function (
      weight,
      windSpeed,
      yearsExp,
      windUnits,
      weightUnits
    ) {
      this.weight = weight
      this.windSpeed = windSpeed
      this.yearsExp = yearsExp
      this.windUnits = windUnits
      this.weightUnits = weightUnits
    }

    // I think this needs to be in a call back function
    let measure = "m"
    if (weightMeasureElement === "pounds") {
      measure = "ft"
      weightElement = weightElement * 2.20462
    }
    if (windMeasureElement === "miles") {
      windElement = windElement * 0.868976
    }
    if (windMeasureElement === "kilometers") {
      windElement = windElement * 0.539957
    }

    Conditions.prototype.convert = function () {}

    Conditions.prototype.calculate = function () {
      if (expElement <= 1) {
        return ((this.weight / this.windSpeed) * f - 1).toFixed(2)
      } else {
        return ((this.weight / this.windSpeed) * f).toFixed(2)
      }
    }

    var conditions = new Conditions(
      weightElement,
      windElement,
      expElement,
      windMeasureElement,
      weightMeasureElement
    )
    if (weightElement === "" || isNaN(weightElement)) {
      setResult(<strong> please enter Weight value !</strong>)
    } else if (windElement === "" || isNaN(windElement)) {
      setResult(<strong>please enter Wind strength value !</strong>)
    } else {
      setResult(
        <span>
          Recommended kite size {conditions.calculate()} {measure} <sup>2</sup>
        </span>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Kite Size Calculator - Recommended Kiteboarding Kite Size Calculation"
        description="Calculate the recommended kite size, kite sizes are determined according to here weight and wind speed."
        keywords={[
          "Kite Size Calculator, Kiteboarding size calculator, kite calculation, Kiteboarding kite calculation.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Kite Size Calculator</Title>
        <SubTitle>Recommended Kite Size Calculator</SubTitle>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Weight</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  name="weight"
                  ref={weight}
                  placeholder="0"
                  required
                />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="Weight"
                    ref={weightMeasure}
                  >
                    <option value="kilograms">kg</option>
                    <option value="pounds">lbs</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Wind strength</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  name="wind"
                  ref={wind}
                  placeholder="0"
                  required
                />
              </Control>
              <Control>
                <Select>
                  <select
                    className="staticSelect"
                    aria-label="Wind"
                    ref={windMeasure}
                  >
                    <option value="knots">kts</option>
                    <option value="kilometers">kph</option>
                    <option value="miles">mph</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>experience (not required)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  name="experience"
                  ref={exp}
                  placeholder="0"
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  years
                </StaticButton>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVol}
            >
              Calculate Volume
            </Button>
            <MessageParagraph>{Result}</MessageParagraph>
          </Column>
          <Column>
            <FlexDiv maxWidth="350px" margin="auto">
              <SmallImg filename="kite.png" alt="kite calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <h3>Kite</h3>
        <p>
          kite is much than the weight of the air, craft with wing exteriors
          that operate against the air to create lift and pull.
        </p>
        <br />
        <h3> Finds out the kite size</h3>
        <p>
          kite sizes are determined according to here weight and wind speed.
          <br />
          Approximate kite sizes for the beginners : <br />
          <strong>
            &#60; 120 lbs = 5 m<sup>2</sup>
          </strong>
          <br />
          <strong>
            121 - 150 lbs = 7 m<sup>2</sup>
          </strong>
          <br />
          <strong>
            151 - 175 lbs = 9 m<sup>2</sup>
          </strong>
          <br />
          <strong>
            176 - 200 lbs = 11 m<sup>2</sup>
          </strong>
          <br />
          <strong>
            &#62; 200 lbs = 13 m<sup>2</sup>
          </strong>
          <br />
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default KiteSizeCalculator
